import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import Input from '../../../components/Inputs';
import toast from '../../../utils/toast';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import TextArea from '../../../components/TextArea';
import { createUpdateDok } from '../../../actions/create-update-dok';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import SelectField from '../../../components/Select/Select';
import { getSpecialitiesDoctolib } from '../../../actions/admin/getSpecialitiesDoctolib';
import { getDok } from '../../../actions/contibutor/getDok';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const CreateUpdateDokContributor = ({
  setOpenModal,
  lists,
  data = {},
  columnId,
  indexList,
  index,
  setIsLoadingPage,
  type,
  listID,
  noDirectSave
}) => {
  const location = useLocation();
  let navigate = useNavigate();
  const store = useStore();
  const [buttonClicked, setButtonClicked] = useState('default');
  const [isLoading, setIsLoading] = useState(false);
  const dokValidationSchema = yup.object().shape({
    title: yup
      .string()
      .max(50, ({ max }) => 'Maximum 50 caractères')
      .required('Champ requis !'),
    url: yup.string().required('Champ requis !'),
    source: yup.string(),
    color: yup.string(),//.required('Champ requis !'),
    description: yup.string().required('Champ requis !'),
    skills: yup.array().required('Champ requis !'),
    speciality_data_source_id: yup.string().required('Champ requis'),
  });
  const paramsExpertise = useSelector((state) => state?.admin?.paramsExpertise);
  const [start, setStart] = useState(true);
  let { id } = useParams();

  const dokData = location.state?.dokData || null;

  useEffect(() => {
    if (dokData) {
      // Handle the DOK data - for example, you can auto-save the DOK upon navigating to this page
      saveDokAfterLogin(dokData);
    }
  }, [dokData]);

  const saveDokAfterLogin = async (dokData) => {
    try {

      if (dokData.list_id === 'empty') dokData.list_id = '';
      dokData.column_id = '00000000-0000-0000-0000-000000000000';
      dokData.type = 'by_my';
      dokData.status = true;
      const result = await createUpdateDok(
        null,
        dokData,
        store,
        indexList,
        index,
        'by_my',
        true,
      );

      if (result?.type === 'success') {
        toast.showSuccess(store, 'DOK saved successfully after login!');
        // Optionally, navigate to another page or reload the list
        navigate('/contributor/doks');
      } else {
        toast.showError(store, result?.message || 'Failed to save DOK');
      }
    } catch (error) {
      console.log(dokData);
      console.log(error);
      toast.showError(store, 'Error while saving DOK after login.');
    }
  };

  useEffect(() => {
    if (start) {
      setStart(false);
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      getDok(id, store);
      getSpecialitiesDoctolib(store, { ...paramsExpertise, limit: 180 });
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
      return () => {};
    } else setStart(false);
  }, [start]);
  
  const fetchMetadata = async (url, setFieldValue) => {
    try {
      setIsLoading(true); // Start loading
      await axios
      .get(`${process.env.REACT_APP_PUBLIC_URL}/api/metadata/external`, { params: { url } })
      .then(async ({ data }) => {
        setFieldValue('title', data.title || '');
        setFieldValue('description', data.description || '');
      })
      .catch((apiError) => {
          if (apiError.response && apiError.response.status === 503) {
          
              console.error('Error:', apiError);
              alert('Failed to send email. Please try again later.');
          }
          return Promise.reject(apiError);
      });
    } catch (error) {
      console.error('Failed to fetch metadata', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if (buttonClicked === 'registerContributor') {
        // Redirect to "Register Contributor" page with the DOK data
        navigate('/auth/register/contributor', { state: { dokData: values } });
      } else if (noDirectSave) {
        // If noDirectSave is true, navigate to the login page and pass the DOK object to be saved after login.
        navigate('/auth/login?type=cbt', { state: { dokData: values } });
      } else {
        if (values.list_id === 'empty') values.list_id = '';
        values.column_id = '00000000-0000-0000-0000-000000000000';
        values.type = 'by_my';
        values.status = true;
        const result = await createUpdateDok(
          id || null,
          values,
          store,
          indexList,
          index,
          'by_my',
          true,
        );
        
        if (result?.type === 'success') {
          console.log(result);
          navigate(-1);
          setIsLoading(false);
          //setIsLoadingPage(true);
          setOpenModal(false);
          toast.showSuccess(store, result?.message);
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        } else {
          setIsLoading(false);
          toast.showError(store, result?.message);
          store.dispatch({
            type: LOADING_STOPED,
            payload: {},
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const specialities = useSelector(
    (state) => state?.admin?.listSpecialitiesDoctolib.data,
  );
  const dok = useSelector((state) => state?.contributor?.dok);
  return (
    <div className={noDirectSave ? 'page-content m-30' : 'page-content w-100'}>
      <div className="card admin-card shadow-sm border-0">
        <div className="card-header">
          <h5 className="mb-0">{id ? 'Modifier un DOK' : 'Créer un DOK'}</h5>
        </div>
        <Formik
          className="login100-form validate-form"
          initialValues={{
            source: dok?.source || '',
            color: dok?.color || '',
            title: dok?.title || '',
            speciality_data_source_id: dok?.speciality_data_source_id || '',
            url: dok?.url || '',
            skills: dok?.skills ? JSON.parse(dok?.skills) : [],
            description: dok?.description || '',
            status: dok?.status || false,
            column_id: columnId,
          }}
          validationSchema={dokValidationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            handleChange,
            values,
          }) => (
            <>
              <div className="card-body">
                <div style={{ display: 'grid', gap: 10 }}>
                  <Input
                    disabled={dok && dok?.campagne}
                    handleSubmit={handleSubmit}
                    handleChange={(e) => {
                      handleChange(e);
                      const url = e.target.value;
                      if (url) {
                        fetchMetadata(url, setFieldValue);
                      }
                    }}
                    width="col-lg-6"
                    value={values.url}
                    errors={errors}
                    touched={touched}
                    label="Url"
                    type="url"
                    pattern="https://.*"
                    name="url"
                    placeholder="https://."
                  />
                {isLoading && <Spinner animation="border" size="sm" />}
                  <Input
                    counter={true}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    width="col-lg-6"
                    value={values.title}
                    errors={errors}
                    touched={touched}
                    label="Titre"
                    type="text"
                    name="title"
                    placeholder="Titre"
                  />
                  <SelectField
                    isMulti={false}
                    width="col-lg-6"
                    options={specialities || []}
                    label="Spécialité"
                    handleChange={({ value }) =>
                      setFieldValue('speciality_data_source_id', value)
                    }
                    name="speciality_data_source_id"
                    value={values.speciality_data_source_id}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    touched={touched}
                  />
                  <Row>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label>
                          Veuillez sélectionner une couleur
                        </Form.Label>
                        <div className="row ">
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                className="colorinput-input"
                                value="#45aaf2"
                                checked={values.color === '#45aaf2'}
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-azure"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                className="colorinput-input"
                                value="#6574cd"
                                checked={values.color === '#6574cd'}
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-indigo"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#8927ec"
                                checked={values.color === '#8927ec'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-purple"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#fc5296"
                                checked={values.color === '#fc5296'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-pink"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#e73827"
                                checked={values.color === '#e73827'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-red"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#fc7303"
                                checked={values.color === '#fc7303'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-orange"></span>
                            </label>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#FBB034"
                                checked={values.color === '#FBB034'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-yellow"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#7bd235"
                                checked={values.color === '#7bd235'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-lime"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#4ecc48"
                                checked={values.color === '#4ecc48'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-green"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#1caf9f"
                                checked={values.color === '#1caf9f'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-teal"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#007ea7"
                                checked={values.color === '#007ea7'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-cyan"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#3223f1"
                                checked={values.color === '#3223f1'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-blue"></span>
                            </label>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#5a6970"
                                checked={values.color === '#5a6970'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-gray"></span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="colorinput">
                              <input
                                name="color"
                                type="radio"
                                value="#05c3fb"
                                checked={values.color === '#05c3fb'}
                                className="colorinput-input"
                                onChange={handleChange}
                              />
                              <span className="colorinput-color bg-secondary"></span>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <input
                              name="color"
                              type="color"
                              value={values.color}
                              defaultValue={values.color}
                              onChange={handleChange}
                            />{' '}
                            <label
                              style={{
                                marginLeft: 10,
                              }}
                            >
                              Choisir une autre couleur
                            </label>
                          </div>
                        </div>
                        {errors && errors?.color && touched?.color && (
                          <Row>
                            <div
                              className="bg-danger-transparent-2 text-danger"
                              role="alert"
                              style={{
                                width: '100%',
                                fontSize: '11px',
                              }}
                            >
                              {errors?.color}
                            </div>
                          </Row>
                        )}
                        <br />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Alert
                        style={{
                          alignContent: 'center',
                          display: 'flex',
                          width: '50%',
                          gap: 10,
                        }}
                        key="'"
                        variant="success"
                      >
                        <i
                          style={{ fontSize: 30 }}
                          className="fas fa-info-circle"
                        ></i>
                        Cette couleur pourra ensuite etre modifiée par le
                        professionnel de santé
                      </Alert>
                    </Col>
                  </Row>
                  <h3 className="card-title">Options de référencement</h3>
                  <hr />

                  <TextArea
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    width="col-lg-6"
                    value={values.description}
                    errors={errors}
                    touched={touched}
                    label="Url"
                    type="text"
                    name="description"
                    placeholder="Annoter votre description et la source du lien"
                    rows={4}
                  />
                  <Input
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    width="col-lg-6"
                    value={values.source}
                    errors={errors}
                    touched={touched}
                    type="text"
                    name="source"
                    placeholder="Source (conseillé)"
                    label="Source (conseillé)"
                  />
                  <Input
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    width="col-lg-6"
                    value={values.skill}
                    errors={errors}
                    touched={touched}
                    label="Associer des mots-clés"
                    type="text"
                    name="skill"
                    placeholder="ex : diabète"
                    icon={
                      <button
                        onClick={() => {
                          if (
                            values.skill /*&&
                          chipData.some((elem) => elem.label === values.skill)*/
                          )
                            setFieldValue('skills', [
                              ...values.skills,
                              {
                                key:
                                  values.skills.length === 0
                                    ? 1
                                    : values.skills[values.skills.length - 1]
                                        .key + 1,
                                label: values.skill,
                              },
                            ]);
                          setFieldValue('skill', '');
                        }}
                        className="btn btn-default"
                      >
                        Ajouter
                      </button>
                    }
                  />
                  {values.skills.length > 0 && (
                    <Paper
                      sx={{
                        display: 'flex',
                        width: '85%',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                      }}
                      component="ul"
                    >
                      {values.skills.map((data) => {
                        return (
                          <ListItem key={data.key}>
                            <Chip
                              label={data.label}
                              onDelete={() =>
                                setFieldValue(
                                  'skills',
                                  values.skills.filter(
                                    (chip) => chip.key !== data.key,
                                  ),
                                )
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </Paper>
                  )}
                  {errors && errors.skills && (
                    <>
                      <small id="emailHelp" className="form-text text-danger">
                        {errors.skills}
                      </small>
                    </>
                  )}
                </div>
                <div />
              </div>
              <div className="card-footer d-flex justify-content-between align-items-center">
              {!noDirectSave ? (
                <div>
                  <Link to="/contributor/doks">
                    <i className="fas fa-reply-all me-2"></i>Retour à la liste
                  </Link>
                </div>
              ): ""}
                <div>
                  {!noDirectSave ? (<button
                    onClick={() => navigate('/contributor/doks')}
                    className="btn btn-danger"
                    type="button"
                  >
                    Annuler
                  </button>) : (<button
                    onClick={() => {
                      setButtonClicked('registerContributor');
                      handleSubmit(); // Call handleSubmit with the button identifier set
                    }}
                    className="btn btn-primary"
                    type="button"
                    id='registerContributor'
                  >
                    Créer un compte
                  </button>)}
                  <button
                    onClick={handleSubmit}
                    className={
                      isLoading
                        ? 'link-light btn btn-primary ms-3'
                        : ' login100-form-btn link-light btn btn-primary ms-3'
                    }
                    type="button"
                  >
                    {id ? 'Modifier' : (noDirectSave ? 'Se connecter' : 'Ajouter')}
                  </button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateUpdateDokContributor;
