import { createSlice } from '@reduxjs/toolkit';

export const contributorSlice = createSlice({
  name: 'contributor',
  initialState: {
    listDoks: [],
    paramsDoks: {
      current_page: 1,
      limit: 10,
      search: '',
      order: '',
      field: '',
    },
    dok: null,
    packsContributor: [],
    sumDoks: null,
    currentPack: null,
    payment: {
      notificationCount: 0,
      searchCount: 0,
      lateralBarCount: 0,
    },
    billsPacks: [],
    billsCampagne: [],
  },
  reducers: {
    getBillPack: (state, action) => {
      const { bills } = action.payload;
      state.billsPacks = bills;
    },
    getBillCampagne: (state, action) => {
      const { bills } = action.payload;
      state.billsCampagne = bills;
    },
    payment: (state, action) => {
      const { payment } = action.payload;
      state.payment = payment;
    },
    getAllDoks: (state, action) => {
      const { list, params } = action.payload;
      state.listDoks = list;
      state.paramsDoks = params;
    },
    getDok: (state, action) => {
      const { data } = action.payload;
      state.dok = data;
    },
    getAllPacksContributor: (state, action) => {
      const { list } = action.payload;
      state.packsContributor = list.data;
    },
    getCurrentPackDok: (state, action) => {
      const { data } = action.payload;
      state.currentPack = data;
    },
    getSumDok: (state, action) => {
      const { data } = action.payload;
      state.sumDoks = data;
    },
  },
});

export const GET_SUM_DOKS = 'contributor/getSumDok';
export const GET_BILLS_PACKS = 'contributor/getBillPack';
export const GET_BILLS_CAMPAGNE = 'contributor/getBillCampagne';
export const PAYMENT = 'contributor/payment';
export const GET_CURRENT_PACK = 'contributor/getCurrentPackDok';
export const GET_LIST_PACKS_DOK_CONTRIBUTOR =
  'contributor/getAllPacksContributor';
export const GET_LIST_DOK_CONTRIBUTOR = 'contributor/getAllDoks';
export const GET_DOK = 'contributor/getDok';

export default contributorSlice.reducer;
