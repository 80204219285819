import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/Modal/Modal';
import { deleteDok } from '../../../actions/delete-dok';
import toast from '../../../utils/toast';
import { getAllDoks } from '../../../actions/contibutor/getAllDoks';
import DOC from '../../pds/Components/open-url-dok';
import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import InstagramIcon from '@mui/icons-material/Instagram';
import Skeleton from '@mui/material/Skeleton';
import { GET_DOK } from '../../../redux/reducers/contributor';
import { getCurrentPack } from '../../../actions/contibutor/getCurrentPack';
import { getSumDoks } from '../../../actions/contibutor/get-sum-doks';

export default function DoksContributor() {
  const store = useStore();
  const navigate = useNavigate();
  const [modalUpdateStatus, setModalUpdateStatus] = useState(false);
  const [index, setIndex] = useState(null);
  const [idPds, setIdPds] = useState(null);
  const [dataDok, setDataDok] = useState(null);
  const [modalPageDok, setModalPageDok] = useState(false);

  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageDoks')),
  );
  const [search, setSearch] = React.useState(
    localStorage.getItem('searchDoks'),
  );
  const [loading, setLoading] = useState(true);
  const params = useSelector((state) => state?.admin?.paramsDoks);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });
    getSumDoks(store);
    getCurrentPack(store);
    getAllDoks(
      store,
      {
        ...params,
        current_page: page || 1,
        search: search || '',
      },
      'contributor',
    );
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);

  const sumDoks = useSelector((state) => state?.contributor?.sumDoks);
  const currentPack = useSelector((state) => state?.contributor?.currentPack);
  const listDoks = useSelector((state) => state?.contributor?.listDoks);
  const totalPage = listDoks?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  console.log(sumDoks);
  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllDoks(store, newParams);
      localStorage.setItem('pageDoks', nextSelectedPage + 1);
      if (localStorage.getItem('search')) {
        navigate(
          `/admin/doks?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('search')}`,
        );
      } else {
        navigate(`/admin/doks?page=${nextSelectedPage + 1}`);
      }
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllDoks(store, newParams);
    localStorage.setItem('searchDoks', value);
    if (localStorage.getItem('pageDoks')) {
      navigate(`/admin/doks?page=${1}&search=${value}`);
    }
    setLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const onSubmitDeleteDok = async (id, indexDok) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await deleteDok(id || null, indexDok, null, store);
      if (result?.type === 'success') {
        setIsLoading(false);
        setModalUpdateStatus(false);
        getAllDoks(store, params);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };

    await getAllDoks(store, newParams);
    setLoading(false);
  };
  const headers = [
    {
      title: 'Titre',
      width: '25%',
      className: 'text-uppercase fw-semibold text-black-50',
      filed: `"title"`,
    },
    {
      title: 'Url',
      width: '50%',
      className: 'text-uppercase fw-semibold  text-black-50',
      filed: `"url"`,
    },
    {
      title: 'Action',
      width: '15%',
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div
              style={{
                gap: 15,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link
                onClick={() =>
                  store.dispatch({
                    type: GET_DOK,
                    payload: {
                      data: null,
                    },
                  })
                }
                className="link-light btn btn-lg btn-primary"
                to="/contributor/doks/create"
              >
                <i className="fas fa-plus-circle me-2"></i>Créer un Dok
              </Link>{' '}
              <span>
                {currentPack
                  ? `(${currentPack?.pack?.nbr_dok - sumDoks} DOKs
                restants sur ${currentPack?.pack?.nbr_dok})`
                  : ''}
              </span>
            </div>
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par par mot clé"
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(
              listDoks?.data,
              setModalUpdateStatus,
              setIndex,
              setIdPds,
              setDataDok,
              setModalPageDok,
              loading,
            )}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {listDoks?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={listDoks?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      {dataDok?.url && (
        <ModalComponent
           body={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a
                href={dataDok?.url}
                target="_blank"
                className="btn btn-primary me-2"
                title="Voir DOK"
              >
                Voir DOK
              </a>
            </div>
          }
          showFooter={true}
          title={
              <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                <span>Partager sur: </span>
                <div
                  style={{ display: 'flex', gap: 6 }}
                  className="avatar-list ms-3"
                >
                  <FacebookShareButton
                      url={dataDok?.url}
                      quote={"Partager par Dokatis: " + dataDok?.url}
                      hashtag={'#dokatis'}
                      description={dataDok?.description}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <br />
                    <TwitterShareButton
                      title={"Partager par Dokatis: " + dataDok?.title}
                      url={dataDok?.url}
                      hashtags={['dokatis']}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      url={dataDok?.url}
                      quote={"Partager par Dokatis: " + dataDok?.url}
                      hashtag={'#dokatis'}
                      description={dataDok?.description}
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <br />
                    <InstapaperShareButton
                      title={"Partager par Dokatis: " + dataDok?.title}
                      url={dataDok?.url}
                      description={dataDok?.description}
                      hashtags={['dokatis']}
                    >
                      <InstagramIcon size={32} round />
                    </InstapaperShareButton>
                    <LinkedinShareButton
                      title={"Partager par Dokatis: " + dataDok?.title}
                      summary={dataDok?.description}
                      url={dataDok?.url}
                      hashtags={['dokatis']}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
              </div>
            </div>
          }
          edge="end"
          size="lg"
          onHide={() => {
            setModalPageDok(false);
          }}
          show={modalPageDok}
        />
      )}
      <ModalComponent
        body="Voulez-vous vraiment supprimer ce DOK"
        title="Supprimer DOK"
        show={modalUpdateStatus}
        onHide={() => setModalUpdateStatus(false)}
        footer={
          <button
            style={{
              backgroundColor: '#00B0F0',
              borderColor: '#00B0F0',
              color: 'white',
            }}
            onClick={() => onSubmitDeleteDok(idPds, index)}
            className="btn btn-primary me-2"
            type="button"
          >
            Supprimer
          </button>
        }
        size="md"
      />
    </div>
  );
}

const getColumns = (
  data,
  setModalUpdateStatus,
  setIndex,
  setIdPds,
  setDataDok,
  setModalPageDok,
  isLoading,
) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width="100%" height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width="100%" height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr key={index}>
            <td>
              {item.title}
              <br />
            </td>
            <td className="text-start">
              <a target="_blank" href={item.url}>
                {item.url}
              </a>
            </td>
            <td className="text-center">
              <Link
                to={`/contributor/doks/statistic/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-graph-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"
                  />
                </svg>
              </Link>
              <Link
                to={`/contributor/doks/update/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to=""
                className="btn btn-primary btn-sm btn-action"
                onClick={() => {
                  setDataDok(item);
                  setModalPageDok(true);
                }}
              >
                <i className="far fa-eye"></i>
              </Link>
              <button
                onClick={() => {
                  setIndex(index);
                  setIdPds(item.id);
                  setModalUpdateStatus(true);
                }}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="fas fa-times"></i>
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colspan={4}>
            Vous n'avez pas encore de doks
          </td>
        </tr>
      )}
    </>
  );
};
