import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import moment from 'moment/moment';

export const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = localStorage.getItem('token');
  if (token !== null && (!token || isTokenExpired(token))) {
    // try for a new token
    return axios
      .post(`${process.env.REACT_APP_PUBLIC_URL}/api/auth/refreshToken`, {
        token: token,
      })
      .then(async ({ data }) => {
        const response = data;

        const newExtendedParams = {
          ...params,
          url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `JWT ${response.data.token}`,
          },
        };
        localStorage.setItem('connected', true);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.token);
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === 503) {
          localStorage.setItem('connected', false);
          //document.location.reload();
        }
        return Promise.reject(apiError);
      });
  }
  const extendedParams = {
    ...params,
    url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `JWT ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === 503) {
      localStorage.setItem('connected', false);
      //document.location.reload();
    }
    return Promise.reject(apiError);
  });
};

export const hasRole = (roles, roleUser) => {
  if (roles && roles.length > 0) {
    const userRoleCodes = roles.map((role) => {
      if (role.code === roleUser) return true;
      else return false;
    });
    if (userRoleCodes[0]) return true;
    return false;
  }
  return false;
};

export const minutesToHours = (num) => {
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + 'h' + rminutes + 'mn';
};

export const roles = {
  ADM: 'admin',
  PDS: 'pds',
  CBT: 'contributor',
};

export const formatDnd = (lines) => {
  let lineColumns = {};
  const columns = [];
  lines.length > 0 &&
    lines.map((line, index) => {
      lineColumns = {
        ...lineColumns,
        [line.id]: {
          ...columns,
        },
      };
    });
  return lineColumns;
};
export const formatHourly = async (hourlies) => {
  console.log(hourlies);
  const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  let data = [];
  days.map((day, index) => {
    if (hourlies.split(day)[index]) data.push(hourlies.split(day)[index]);
  });
  return data;
};

export default function nl2br(str, is_xhtml) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  var breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2',
  );
}

export const formatAmount = (amount) => {
  return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(
    amount,
  );
};
export const returnPacks = (contributorPacks, allPacks) => {
  let packs = [];
  if (contributorPacks && contributorPacks.length > 0) {
    contributorPacks.map((pack) => {
      packs = allPacks.filter(
        (item) => item.id !== pack.pack.id && item.nbr_dok > pack.pack.nbr_dok,
      );
      console.log(packs);
    });
    return packs;
  } else return allPacks;
};
export const getDaysArray = (data, type) => {
  var dates = [];
  if (data && data.length > 0 && type === 1) {
    data.map((item) => dates.push(item.totalCount));
  }
  if (data && data.length > 0 && type === 2) {
    data.map((item) =>
      dates.push(moment(item.month).format('MMMM').substring(0, 3)),
    );
  }

  return dates;
};
export const getPdsArray = (data, type) => {
  var dates = [];
  if (data && type === 1) {
    data.data.map((item, index) => {
      dates.push(
        parseFloat(
          (item.totalCount / data.pdsCount[index].pdsCount) * 100,
        ).toFixed(2),
      );
    });
  }
  if (data && type === 2) {
    data.data.map((item, index) => {
      dates.push(item.speciality_data_source.title);
    });
  }
  console.log(dates);
  return dates;
};
