import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import { GET_PDS_BY_ID } from '../../../redux/reducers/search';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import { getAllAdvantageCode } from '../../../actions/admin/getAllAdvantageCode';

export default function AdvantageCode() {
  const store = useStore();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageCode')),
  );

  const [search, setSearch] = React.useState(
    localStorage.getItem('searchCode'),
  );
  const params = useSelector((state) => state?.admin?.paramsAdvantageCode);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllAdvantageCode(store, {
      ...params,
      limit: 10,
      current_page: page || 1,
      search: search || '',
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const listAdvantageCode = useSelector(
    (state) => state?.admin?.listAdvantageCode,
  );
  const totalPage = listAdvantageCode?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);

  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllAdvantageCode(store, newParams);
      localStorage.setItem('pageCode', nextSelectedPage + 1);
      if (localStorage.getItem('searchCode')) {
        navigate(
          `/admin/notifications?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('searchCode')}`,
        );
      } else {
        navigate(`/admin/notifications?page=${nextSelectedPage + 1}`);
      }
    }
    scrollTo('#pds-data');
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllAdvantageCode(store, newParams);
    localStorage.setItem('searchCode', value);
    if (localStorage.getItem('pageCode')) {
      navigate(`/admin/notifications?page=${1}&search=${value}`);
    }
    if (
      !localStorage.getItem('pageCode') &&
      !localStorage.getItem('searchCode')
    )
      navigate(`/admin/notifications?search=${value}`);
    setLoading(false);
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };
    await getAllAdvantageCode(store, newParams);
    setLoading(false);
  };

  const headers = [
    {
      title: 'Titre',
      filed: `"title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Date de création',
      filed: `"created_at"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Utilisés',
      filed: `"created_at"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Action',
      width: 65,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link
              onClick={() =>
                store.dispatch({
                  type: GET_PDS_BY_ID,
                  payload: {
                    data: null,
                  },
                })
              }
              className="link-light btn btn-lg btn-primary"
              to="/admin/advantages-code/create"
            >
              <i className="fas fa-plus-circle me-2"></i>Créer un code
            </Link>
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par nom ..."
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(listAdvantageCode?.data, loading)}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {listAdvantageCode?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={listAdvantageCode?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getColumns = (data, isLoading) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>{' '}
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {`${item.title} ${item.reduction}%`}
              <br />
            </td>
            <td>
              {moment(item.created_at).format('DD/MM/YYYY')}
              <br />
            </td>
            <td>
              {item.is_seen}
              <br />
            </td>
            <td className="text-center">
              <Link
                to={`/admin/advantages-code/detail/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
                href="#"
              >
                <i className="fas fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colspan={4}>
            Vous n'avez pas encore de codes          </td>
        </tr>
      )}
    </>
  );
};
