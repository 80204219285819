import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import { LOADING_STARTED } from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import { GET_PDS_BY_ID } from '../../../redux/reducers/search';
import Skeleton from '@mui/material/Skeleton';
import { getAllNotifications } from '../../../actions/admin/getAllNotifications';
import moment from 'moment';

export default function Notifications() {
  const store = useStore();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageNotifications')),
  );

  const [search, setSearch] = React.useState(
    localStorage.getItem('searchNotifications'),
  );
  const params = useSelector((state) => state?.admin?.paramsNotifications);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllNotifications(store, {
      ...params,
      limit: 10,
      current_page: page || 1,
      search: search || '',
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const listNotifications = useSelector(
    (state) => state?.admin?.listNotifications,
  );
  console.log(listNotifications);
  const totalPage = listNotifications?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);

  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllNotifications(store, newParams);
      localStorage.setItem('pageNotifications', nextSelectedPage + 1);
      if (localStorage.getItem('searchNotifications')) {
        navigate(
          `/admin/notifications?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('searchNotifications')}`,
        );
      } else {
        navigate(`/admin/notifications?page=${nextSelectedPage + 1}`);
      }
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllNotifications(store, newParams);
    localStorage.setItem('searchNotifications', value);
    if (localStorage.getItem('pageNotifications')) {
      navigate(`/admin/notifications?page=${1}&search=${value}`);
    }
    if (
      !localStorage.getItem('pageNotifications') &&
      !localStorage.getItem('searchNotifications')
    )
      navigate(`/admin/notifications?search=${value}`);
    setLoading(false);
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };

    await getAllNotifications(store, newParams);
    setLoading(false);
  };

  const headers = [
    {
      title: 'Titre',
      filed: `"title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: "Date d'envoi",
      filed: `"notification"."created_at"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Lus/Non Lus',
      filed: `"notification_users"."is_seen"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50',
    },
    {
      title: 'Action',
      width: 65,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link
              onClick={() =>
                store.dispatch({
                  type: GET_PDS_BY_ID,
                  payload: {
                    data: null,
                  },
                })
              }
              className="link-light btn btn-lg btn-primary"
              to="/admin/notifications/create"
            >
              <i className="fas fa-plus-circle me-2"></i>Créer une notification
            </Link>
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par nom ..."
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(listNotifications?.data, loading)}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {listNotifications?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={listNotifications?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const getColumns = (data, isLoading) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>{' '}
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => {
          const notifIsSeen = item.notification_users.filter(
            (elm) => elm.is_seen,
          );
          const notifIsNotSeen = item.notification_users.filter(
            (elm) => !elm.is_seen,
          );
          return (
            <tr>
              <td>
                {item.title}
                <br />
              </td>
              <td>
                {moment(item.created_at).format('DD/MM/YYYY')}
                <br />
              </td>
              <td>
                {`${notifIsSeen.length}/${notifIsNotSeen.length}`}
                <br />
              </td>
              <td className="text-center">
                <Link
                  to={`/admin/notifications/detail/${item.id}`}
                  className="btn btn-primary btn-sm btn-action"
                  href="#"
                >
                  <i className="fas fa-eye"></i>
                </Link>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td className="text-center" colspan={4}>
            Vous n'avez pas encore de notifications
          </td>
        </tr>
      )}
    </>
  );
};
