import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/DataTable';
import {
  LOADING_STARTED,
  LOADING_STOPED,
} from '../../../redux/reducers/loader';
import { useSelector, useStore } from 'react-redux';
import PaginatedItems from '../../../components/Paginations';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Link, useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/Modal/Modal';
import toast from '../../../utils/toast';
import Skeleton from '@mui/material/Skeleton';
import { getAllStarterKit } from '../../../actions/admin/starter-kit/get-all-starter-kit';
import { GET_STARTER_KIT } from '../../../redux/reducers/starter-kit';
import { getLines } from '../../../actions/getLines';
import { deleteStarterKit } from '../../../actions/admin/starter-kit/delete-starter-kit';

export default function StarterKit() {
  const store = useStore();
  const navigate = useNavigate();
  const [modalUpdateStatus, setModalUpdateStatus] = useState(false);
  const [index, setIndex] = useState(null);
  const [id, setId] = useState(null);
  const [page, setPage] = React.useState(
    parseInt(localStorage.getItem('pageStarterKit')),
  );

  const [search, setSearch] = React.useState(
    localStorage.getItem('searchStarterKit'),
  );
  const params = useSelector((state) => state?.starterKit?.params);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    store.dispatch({
      type: LOADING_STARTED,
      payload: {},
    });

    getAllStarterKit(store, {
      ...params,
      limit: 10,
      current_page: page || 1,
      search: search || '',
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [store]);
  const list = useSelector((state) => state?.starterKit?.list);
  console.log(list);

  const totalPage = list?.total / 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [rowsCount, setRowsCount] = useState(10);

  const handlePageClick = async (e) => {
    setLoading(true);
    const selectedPage = e.selected;
    const nextSelectedPage = e.nextSelectedPage;
    if (nextSelectedPage || nextSelectedPage === 0) {
      const offset = selectedPage * rowsPerPage;
      setPage(selectedPage);
      setOffset(offset);
      setRowsCount(rowsCount + 10);
      const newParams = {
        ...params,
        limit: 10,
        current_page: nextSelectedPage + 1,
      };
      await getAllStarterKit(store, newParams);
      localStorage.setItem('pageStarterKit', nextSelectedPage + 1);
      if (localStorage.getItem('searchStarterKit')) {
        navigate(
          `/admin/starter-kit?page=${
            nextSelectedPage + 1
          }&search=${localStorage.getItem('searchStarterKit')}`,
        );
      } else {
        navigate(`/admin/starter-kit?page=${nextSelectedPage + 1}`);
      }
    }
    scrollTo('#pds-data');
    setLoading(false);
  };
  const handleChangeSearch = async (value) => {
    setLoading(true);
    const newParams = {
      ...params,
      search: value,
      current_page: 1,
    };
    setSearch(value);
    await getAllStarterKit(store, newParams);
    localStorage.setItem('searchStarterKit', value);
    if (localStorage.getItem('pageStarterKit')) {
      navigate(`/admin/starter-kit?page=${1}&search=${value}`);
    }
    if (
      !localStorage.getItem('pageStarterKit') &&
      !localStorage.getItem('searchStarterKit')
    )
      navigate(`/admin/starter-kit?search=${value}`);
  };
  const handleChangeSort = async (order, field) => {
    setLoading(true);
    localStorage.setItem('order', order);
    localStorage.setItem('field', field);
    const newParams = {
      ...params,
      order,
      field,
    };

    await getAllStarterKit(store, newParams);
    setLoading(false);
  };

  console.log(list);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const onSubmitDelete = async (id, index) => {
    try {
      store.dispatch({
        type: LOADING_STARTED,
        payload: {},
      });
      setIsLoading(true);
      const result = await deleteStarterKit(id || null, index, store);
      if (result?.type === 'success') {
        await getLines(store);
        setIsLoading(false);
        setModalDelete(false);
        toast.showSuccess(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      } else {
        setIsLoading(false);
        toast.showError(store, result?.message);
        store.dispatch({
          type: LOADING_STOPED,
          payload: {},
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response)
        toast.showError(
          store,
          error?.response?.data?.message ||
            'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        );
      store.dispatch({
        type: LOADING_STOPED,
        payload: {},
      });
    }
  };
  const headers = [
    {
      title: 'Nom specialite',
      filed: `"speciality_data_source"."title"`,
      width: '37%',
      className: 'text-uppercase fw-semibold text-black-50 sorting_desc',
    },
    {
      title: 'Action',
      width: 65,
      className: 'text-uppercase fw-semibold text-center text-black-50',
    },
  ];

  return (
    <div className="page-content w-100">
      <div className="card shadow-sm border-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Link
              onClick={() =>
                store.dispatch({
                  type: GET_STARTER_KIT,
                  payload: {
                    data: null,
                  },
                })
              }
              className="link-light btn btn-lg btn-primary"
              to="/admin/starter-kit/create"
            >
              <i className="fas fa-plus-circle me-2"></i>Créer un starter kit
            </Link>
            <div>
              <input
                onChange={(e) => handleChangeSearch(e.target.value)}
                value={search}
                type="text"
                className="form-control"
                placeholder="Filtrer par nom ..."
              />
            </div>
          </div>
          <DataTable
            headers={headers}
            handleChangeSort={handleChangeSort}
            data={getColumns(
              list?.data,
              setModalUpdateStatus,
              setIndex,
              setId,
              loading,
              setModalDelete,
            )}
          />
          <div>
            <div className="d-flex justify-content-end">
              <nav style={{ margin: 0 }}>
                {list?.total > 10 && (
                  <PaginatedItems
                    className="mb-0"
                    handlePageClick={handlePageClick}
                    items={list?.data}
                    itemsPerPage={10}
                    initialPage={page}
                    totalPage={totalPage}
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        body="Voulez-vous supprimer ce DOK"
        title="Supprimer DOK"
        show={modalDelete}
        onHide={() => setModalDelete(false)}
        footer={
          <button
            style={{
              backgroundColor: '#00B0F0',
              borderColor: '#00B0F0',
              color: 'white',
            }}
            onClick={() => onSubmitDelete(id, index)}
            className={
              isLoading
                ? 'btn-loading btn btn-primary me-2'
                : 'btn btn-primary me-2'
            }
            type="button"
          >
            Supprimer
          </button>
        }
        size="md"
      />
    </div>
  );
}

const getColumns = (
  data,
  setModalUpdateStatus,
  setIndex,
  setId,
  isLoading,
  setModalDeleteDok,
) => {
  return (
    <>
      {isLoading ? (
        <>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton animation="wave" width={100} height={10} />
              <br />
            </td>
            <td className="text-center">
              <Skeleton animation="wave" width={100} height={10} />
            </td>
          </tr>
        </>
      ) : data && data.length > 0 ? (
        data.map((item, index) => (
          <tr>
            <td>
              {item?.speciality_data_source?.title}
              <br />
            </td>
            <td className="text-center">
              <Link
                to={`/admin/starter-kit/detail/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="far fa-eye"></i>
              </Link>
              <Link
                to={`/admin/starter-kit/update/${item.id}`}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <button
                onClick={() => {
                  setIndex(index);
                  setId(item.id);
                  setModalDeleteDok(true);
                }}
                className="btn btn-primary btn-sm btn-action"
              >
                <i className="fas fa-times"></i>
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center" colspan={3}>
            Vous n'avez pas encore de starter kit
          </td>
        </tr>
      )}
    </>
  );
};
